import { Column, DropdownItem, TableProps } from "@epignosis_llc/gnosis";
import {
  AssignmentPendingFilter,
  AssignmentPassedFilter,
  AssignmentNotPassedFilter,
} from "@epignosis_llc/gnosis/icons";
import { t } from "@utils/i18n";
import { URLS } from "@constants/urls";
import { QueryFilter } from "types/common";
import { Pagination as IPagination } from "types/responses/index";

const PAGINATION: IPagination = { number: 1, size: 10 };
const SORTING: TableProps["sorting"] = { column: "submission_date", isDescending: false };
export const DEFAULT_FILTERS: QueryFilter[] = [];

export enum SubmissionsFilters {
  status = "[status]",
}

export const DEFAULT_STATE = {
  pagination: PAGINATION,
  sorting: SORTING,
  filters: DEFAULT_FILTERS,
  url: URLS.gradingHub.gradingHub,
};

export const getAssignmentsColumns = (hideColumns?: boolean): Column[] => {
  return [
    {
      accessor: "user",
      cell: t("general.user"),
      classNames: ["users"],
    },
    {
      accessor: "course",
      cell: t("general.course"),
      classNames: ["courseName"],
      hidden: hideColumns,
    },
    {
      accessor: "unit",
      cell: t("general.unit"),
      classNames: ["unitName"],
      hidden: hideColumns,
    },
    {
      accessor: "submission_date",
      cell: t("gradingHub.submissionDate"),
      classNames: ["submissionDate"],
    },
    {
      accessor: "grade_date",
      cell: t("gradingHub.gradeDate"),
      classNames: ["gradeDate"],
    },
    {
      accessor: "grade",
      cell: t("general.actions.grade"),
      classNames: ["grade"],
    },
    {
      accessor: "status",
      cell: t("general.status"),
      classNames: ["status"],
    },
  ];
};

export const actionsColumn = {
  accessor: "actions",
  cell: "",
  classNames: ["hidden"],
  sortableHeader: false,
  headerWidth: 10,
};

export const getAssignmentsFilterOptions = (): DropdownItem[] => [
  {
    label: t("general.progress.pending"),
    value: "pending",
    category: SubmissionsFilters.status,
    icon: AssignmentPendingFilter,
  },
  {
    label: t("general.progress.passed"),
    value: "passed",
    category: SubmissionsFilters.status,
    icon: AssignmentPassedFilter,
  },
  {
    label: t("general.progress.notPassed"),
    value: "not-passed",
    category: SubmissionsFilters.status,
    icon: AssignmentNotPassedFilter,
  },
];

export const mimeTypesWithPreview = [
  "video/mp4",
  "audio/mpeg",
  "application/pdf",
  "image/gif",
  "image/png",
  "image/jpeg",
];

export const NAME_COL_MAX_WIDTH = 200;

export const submissionsMassActionsWordings = {
  reset: {
    progressHeader: "assignment.resetStatus",
    progressMessage: "courses.massActions.resetProgress",
    confirmationHeader: "assignment.resetStatus",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
    confirmationBodyTitle: "assignment.massActions.resetWarning",
    successMessage: "assignment.massActions.resetSuccess",
  },
  remove_from_session: {
    confirmationButton: "general.actions.remove",
    confirmationHeader: "assignment.massActions.removeFromSessionHeader",
    progressHeader: "assignment.massActions.removeFromSessionHeader",
    progressMessage: "assignment.massActions.removeProgress",
    confirmationBodyText: "users.massActions.usersAffectedByMassAction",
    confirmationBodyTitle: "assignment.massActions.removeFromSessionWarning",
    successMessage: "assignment.massActions.removeSuccess",
  },
};
